import { RouterView } from 'vue-router'

const ConsultasList = () => import('@/pages/relatorios/Index.vue')

export default {
  path: '/reports',
  name: 'relatorios',
  component: RouterView,
  children: [
    {
      path: 'search',
      name: 'search',
      component: ConsultasList,
      meta: {
        title: 'Consultas com débitos',
        type: 'search'
      }
    },
    {
      path: 'searchAll',
      name: 'searchAll',
      component: ConsultasList,
      meta: {
        title: 'Consultas',
        type: 'searchAll'
      }
    },
    {
      path: 'login',
      name: 'login-report',
      component: ConsultasList,
      meta: {
        title: 'Logins',
        type: 'login'
      }
    },
    {
      path: 'confirm',
      name: 'confirm',
      component: ConsultasList,
      meta: {
        title: 'Confirmações',
        type: 'confirm'
      }
    },
    {
      path: 'proposal',
      name: 'proposal',
      component: ConsultasList,
      meta: {
        title: 'Propostas',
        type: 'proposal'
      }
    },
    {
      path: 'agreement',
      name: 'agreement',
      component: ConsultasList,
      meta: {
        title: 'Acordos',
        type: 'agreement'
      }
    }
  ]
}
